import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/spindle.ameba.design/spindle.ameba.design/src/components/Layout.tsx";
import SEO from '../../../components/SEO';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageTitle = makeShortcode("PageTitle");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="ブランドボイス - コンテンツ" mdxType="SEO" />
    <PageTitle title="ブランドボイス" enTitle="Brand Voice" mdxType="PageTitle" />
    <p>{`Amebaのサービスバリュー、ブランドパーソナリティからAmebaらしい声質やトーン、言葉選びをブランドボイスとして定義しています。`}</p>
    <p>{`一定の人格としての声を意識し、文脈・状況にあわせてそのトーンとして適切な言葉を選んでください。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/728f4ace2f8882af277d73233950c36c/f941f/brand-voice.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "53.125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAABO0lEQVQoz3WTia4CIQxF+f+vdImJ2+jMiEPLdl9aLaKZ14RQtkN7Cy6lhJwzzCgElFLaWPxaa2v/me1zwzAghNAWbrcBKUb1ZX6eZ/XlYmZGKhkUSQ8LxEBEhBgj3H6//wL6x6NFfDwesdvtdCyHFJiTNgW+oxaowKR30zTp7WbzNDWA3WqHKBA4RVDiNtfWiF5AA5k+/F5Y04iJEVNUaA8U2LIsL+D1etGoJNVxHHE4HHA6nRCWBdM4QjKQXjb7hwdHBv9EKBk2oE2aaYrdnKUvvfceFBmhK0oPrKXCifBWSdVw/mgqm2VdxuKb8L12BrwMF62+2263kEqvVVkqvNls9IAJb++x1IJsmVQgMKkcrr9RbLzfFSjtfD5r9JayPBvdW6o+nScvksbn2eSVKj+9//o5a/b7Y/rxHyG9YEpWxwPjAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/728f4ace2f8882af277d73233950c36c/2aaaf/brand-voice.webp 160w", "/static/728f4ace2f8882af277d73233950c36c/85e47/brand-voice.webp 320w", "/static/728f4ace2f8882af277d73233950c36c/75198/brand-voice.webp 640w", "/static/728f4ace2f8882af277d73233950c36c/61aaa/brand-voice.webp 736w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/728f4ace2f8882af277d73233950c36c/69538/brand-voice.png 160w", "/static/728f4ace2f8882af277d73233950c36c/72799/brand-voice.png 320w", "/static/728f4ace2f8882af277d73233950c36c/6af66/brand-voice.png 640w", "/static/728f4ace2f8882af277d73233950c36c/f941f/brand-voice.png 736w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/728f4ace2f8882af277d73233950c36c/6af66/brand-voice.png",
              "alt": "Amebaのブランドボイスのマッピングイメージ。",
              "title": "Amebaのブランドボイスのマッピングイメージ。",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      